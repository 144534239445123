/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'data.waltti-root'

export const HOST_NAME = 'https://data.waltti.fi'

export const colors = {
    black: '#000',
    white: '#fff',
    red: 'red',
    blue: 'rgb(23, 90, 214)',
    gray: 'rgb(122, 120, 120)',
    'light-gray': 'rgb(201, 197, 197)',
    olive: 'rgb(0, 159, 126)',
    lightBlue: 'rgb(0, 121, 194)',
    darkviolet: '#17083b',
    violet: '#5959a8',

    whiteHover: '#f4f4fa',
    delete: '#ac0000',

    primary: '#000000',
    primaryLight: '#9c9c9c',
    primaryDisabled: '#838383',
    primaryHover: '#303030',
    primaryContrast: '#ffffff'
}
