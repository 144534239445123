/**
 * DocsPage component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import GTFSRealtimeDoc from './GTFSRealtimeDoc'
import TableOfContents from './TableOfContents'

export default class DocsPage extends Component {
    render() {
        return (
            <div className="app-page-container">
                <Helmet>
                    <title>Documentation</title>
                    <meta
                        name="description"
                        content={`Documentation | ${
                            process.env.PLATFORM === 'nysse'
                                ? 'Nysse'
                                : 'Waltti'
                        } API`}
                    />
                </Helmet>
                <TableOfContents data={tableOfContents} />
                <div className="app-centered-content">
                    <div className="app-content-width-controller">
                        <GTFSRealtimeDoc />
                    </div>
                </div>
            </div>
        )
    }
}

const tableOfContents = [
    {
        text: 'GTFS Realtime',
        value: '#gtfs',
        children: [
            { text: 'Introduction', value: '#gtfs-introduction' },
            { text: 'TripUpdate', value: '#gtfs-tripupdate' },
            { text: 'VehiclePosition', value: '#gtfs-vehicleposition' },
            { text: 'Alert', value: '#gtfs-alert' }
        ]
    },
    process.env.PLATFORM === 'nysse'
        ? {
              text: 'SIRI',
              value: '#siri',
              children: [
                  { text: 'Introduction', value: '#siri-introduction' },
                  {
                      text: 'Vehicle Monitoring',
                      value: '#siri-vehiclemonitoring'
                  },
                  { text: 'Stop Monitoring', value: '#siri-stopmonitoring' },
                  { text: 'General Message', value: '#siri-generalmessage' }
              ]
          }
        : null,
    {
        text: 'Static Packages',
        value: '#packages',
        children: [
            { text: 'GTFS', value: '#gtfs-packages' },
            { text: 'NeTEx', value: '#netex-packages' }
        ]
    }
].filter(val => val !== null)
